import React, { useEffect, useState } from "react"
import { LineContext } from "components/context"
import UsersBindingInputPage from "pages/users/binding/input"
import { fetchAndSaveAuthToken } from "services/user.service"
import { setupLiff } from "src/lib/liff"
import { t } from "src/i18n/config"

export default function Home(props) {
  const [message, setMessage] = useState({})
  const [token, setToken] = useState()
  useEffect(() => {
    setupLiff()
      .then(async res => {
        setMessage(res)
        const { userId, liffId } = res
        if (userId && liffId) {
          const t = await fetchAndSaveAuthToken({ userId, liffId })
          setToken(t)
          return
        }
        alert(
          `${t("userNotFound")}${userId}, ${liffId} ${window.location.href}`
        )
      })
      .catch(async err => {
        setMessage(err)
        // const userId = "U30fb0f6e38f7378459a467cac2a2da5e"
        // const liffId = "1655100132-NerKp8jj"
        // const t = await fetchAndSaveAuthToken({ userId, liffId })
        // setToken(t)
      })
  }, [])
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <UsersBindingInputPage {...props} {...message}></UsersBindingInputPage>
      </LineContext.Provider>
    )
  }
  return null
}
